import { render, staticRenderFns } from "./Company.vue?vue&type=template&id=60bd2059&scoped=true&lang=pug&"
import script from "./Company.vue?vue&type=script&lang=js&"
export * from "./Company.vue?vue&type=script&lang=js&"
import style0 from "./Company.vue?vue&type=style&index=0&id=60bd2059&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60bd2059",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/misc.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fopt-in.ru%2Fsrc%2Fviews%2Fuser%2Fprofile%2FCompany.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/user.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fopt-in.ru%2Fsrc%2Fviews%2Fuser%2Fprofile%2FCompany.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports