<i18n src="@/i18n/misc.json"></i18n>
<i18n src="@/i18n/user.json"></i18n>

<template lang="pug">
    .auth-user-profile.view
        div(v-if="auth_user")
            section
                h1 {{ auth_user.name }}
                nav.auth-user-profile-tabs
                    router-link(v-for="(tab, tab_index) in tabs" :to="{name: 'user.profile.' + tab_index}" :key="tab_index") {{ tab.name }}

            section
                .form-container
                    div
                        el-form(:model="company_form", ref="company_form", :rules="company_form_rules", @keydown.enter.native.prevent="saveCompany", label-position="top")
                            el-form-item(prop="name", :label="$t('companies.form.name')")
                                el-input(v-model="company_form.name", maxlength="24", show-word-limit)
                            el-form-item(prop="address", :label="$t('companies.form.address')")
                                el-input(v-model="company_form.address", maxlength="64", show-word-limit)
                            el-form-item(v-if="want_edit_extended_company_info", prop="phone", :label="$t('companies.form.phone')")
                                el-input(v-model="company_form.phone", maxlength="32", show-word-limit)
                            el-form-item(v-if="want_edit_extended_company_info", prop="description", :label="$t('companies.form.email')")
                                el-input(v-model="company_form.email", maxlength="32", show-word-limit)
                            el-form-item(v-if="want_edit_extended_company_info", prop="description", :label="$t('companies.form.description')")
                                el-input(v-model="company_form.description", :rows="6", maxlength="450", type="textarea", show-word-limit)
                            el-button(v-if="!want_edit_extended_company_info", @click="want_edit_extended_company_info = !want_edit_extended_company_info", type="primary" plain) Edit extended info
                            el-button(@click="saveCompany", type="primary") {{ $t('misc.buttons.save') }}
                    .description

</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { GET_ME } from "@/store/types"

    const default_company_form = ()=>{
        return {
            name: '',
            address: '',
            phone: '',
            email: '',
            description: ''
        }
    }

    export default {
        name: "AuthUserProfileCompany",
        data () {
            return {
                current_tab: 'company',
                company_form: default_company_form(),
                company_form_rules: {
                    name: [
                        { required: true, message: this.$t('company.validation.name.required'), trigger: 'blur' },
                        { min: 2, max: 24, message: this.$t('company.validation.name.max'), trigger: 'blur' },
                    ],
                    address: [
                        { min: 2, max: 64, message: this.$t('company.validation.address.max'), trigger: 'blur' },
                    ],
                    phone: [
                        { min: 2, max: 32, message: this.$t('company.validation.phone.max'), trigger: 'blur' },
                    ],
                    email: [
                        { min: 2, max: 32, message: this.$t('company.validation.email.max'), trigger: 'blur' },
                    ],
                    description: [
                        { max: 450, message: this.$t('company.validation.description.max'), trigger: 'blur' },
                    ],
                },
                want_edit_extended_company_info: false,
            }
        },
        computed: {
            ...mapGetters([
                'auth_user',
            ]),
            tabs(){
                return this.$t('user.profile.tabs')
            },
        },
        mounted() {
            if(!this.auth_user.loggedIn()) this.$router.push({ name: 'user.login' })

            this.getMe()
        },
        methods: {
            ...mapActions([
                GET_ME,
            ]),
            getMe(){
                return this.GET_ME().then(()=>{
                    // Map user's company data
                    this.company_form = { ...default_company_form(), ...this.auth_user.company }
                }).catch((error)=>{
                    this.$root.pushAppMessages(error.response.data)
                })
            },
            saveCompany(){
                this.$refs['company_form'].validate((valid) => {
                    if (valid) {
                        // this.SAVE_COMPANY(this.company_form)
                        //     .then(()=>{
                        //         this.$root.pushAppMessages(this.$t('messages.saved'), 'success')
                        //     }).catch((error)=>{
                        //     this.$root.pushAppMessages(error.response.data)
                        // })
                    } else return false
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .auth-user{
        &-profile{
            &-tabs{
                @include font--heading;
                @include font--medium;

                a{
                    display: inline-block;
                    margin: 0 2rem 0 0;

                    &.router-link-active{
                        text-decoration: none;
                    }

                    &:last-child{
                        margin-right: 0;
                    }

                    @media (max-width: $max-width--mobile){
                        display: block;
                        margin: 0 0 1rem 0;
                    }
                }
            }
        }
    }
</style>
